.transfer .nav-link {
    color: #a7a7a7;
}

.transfer .nav-tabs .nav-link{
    border: none;
}

.transfer .nav-tabs .nav-link.active{
    color: #4CC800;
    border-bottom: 2px solid #4CC800;
}
.transfer .nav-tabs .nav-link.active::before{
   content: "";
   width: 20px;
}

.transfer .nav-tabs {
     border-bottom: none
}

.css-13cymwt-control{
    width: 421px;
    height: 52px;
    border-radius: 32px !important;
    margin-bottom: 20px;
    padding-left: 20px;
    background-color: #F5F6FA;
}

