@tailwind base;
@tailwind components;
@tailwind utilities;

.white-icon{
    fill: #D9DBDC;
}

.gradient-green{
    background: linear-gradient(216.59deg, #000000 8.14%, #4CC800 161.28%);
}