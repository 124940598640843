.Sidebar{
    flex: 1;
    background-color:#181818;
    height: 1000px;
    position: sticky;
    z-index: 1;
    width: 276px;
    overflow-x: hidden;
    min-width: 276px;
    max-width: 276px;
}

.active{
    color: red;
}
