.widgetLg {
  flex: 2;
}

.widgetSm {
  flex: 1;
}

.green-gradient {
  background: linear-gradient(159.69deg, #4cc800 13.51%, #15cf74 111%);
  box-shadow: 0px 14px 54px rgba(0, 0, 0, 0.08);
}

input{
  color: black
}

