.transfer .nav-link {
    color: #a7a7a7;
}

.transfer .nav-tabs .nav-link{
    border: none;
}

.transfer .nav-tabs .nav-link.active{
    color: #4CC800;
    border-bottom: 2px solid #4CC800;
}
.transfer .nav-tabs .nav-link.active::before{
   content: "";
   width: 20px;
}

.transfer .nav-tabs {
     border-bottom: none
}

.select_trans{
    color: transparent;
}