.react-tel-input .form-control{
    width: 400px;
    height: 50px;
    border-radius: 20px;
}

.react-tel-input .selected-flag{
    border-radius: 20px;
}

.react-tel-input .flag-dropdown{
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.dodsqv, .bgAEuK {
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.knfclt, .frKUAM{
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.knfclt > span, .dodsqv > span, .frKUAM > span {
    margin-top: -6px;
}

.bgAEuK {
    align-items: center !important;
}